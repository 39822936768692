<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="提现金额" prop="money" >
        <a-input v-model="form.money" placeholder="请输入提现金额" />
      </a-form-model-item>
      <a-form-model-item label="实际需要转账金额" prop="realMoney" >
        <a-input v-model="form.realMoney" placeholder="请输入实际需要转账金额" />
      </a-form-model-item>
      <a-form-model-item label="0-未审核 1-审核未通过 2-审核已通过" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="提现账号" prop="accountNum" >
        <a-input v-model="form.accountNum" placeholder="请输入提现账号" />
      </a-form-model-item>
      <a-form-model-item label="提现账号姓名" prop="accountName" >
        <a-input v-model="form.accountName" placeholder="请输入提现账号姓名" />
      </a-form-model-item>
      <a-form-model-item label="银行名称" prop="bankName" >
        <a-input v-model="form.bankName" placeholder="请输入银行名称" />
      </a-form-model-item>
      <a-form-model-item label="银行卡开户行地址" prop="bankAdress" >
        <a-input v-model="form.bankAdress" placeholder="请输入银行卡开户行地址" />
      </a-form-model-item>
      <a-form-model-item label="提现手续费" prop="serviceChargeNum" >
        <a-input v-model="form.serviceChargeNum" placeholder="请输入提现手续费" />
      </a-form-model-item>
      <a-form-model-item label="1：支付宝 2：微信 3：银行" prop="withdrawType" >
      </a-form-model-item>
      <a-form-model-item label="第三方订单号(微信或支付的交易单号)" prop="orderNo" >
        <a-input v-model="form.orderNo" placeholder="请输入第三方订单号(微信或支付的交易单号)" />
      </a-form-model-item>
      <a-form-model-item label="审核意见" prop="auditOpinion" >
        <a-input v-model="form.auditOpinion" placeholder="请输入审核意见" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWithdraw, addWithdraw, updateWithdraw } from '@/api/user/withdraw'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        money: null,

        realMoney: null,

        status: '0',

        accountNum: null,

        accountName: null,

        bankName: null,

        bankAdress: null,

        serviceChargeNum: null,

        withdrawType: null,

        orderNo: null,

        auditOpinion: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        money: null,
        realMoney: null,
        status: '0',
        accountNum: null,
        accountName: null,
        bankName: null,
        bankAdress: null,
        serviceChargeNum: null,
        withdrawType: null,
        orderNo: null,
        auditOpinion: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWithdraw({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWithdraw(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addWithdraw(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
